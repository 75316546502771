import React, {Component} from 'react';

class ProjectileMotionSim extends Component {

  constructor(props) {
    super(props);
    console.log(this.props);
  }


  render() {

    return (
      <div></div>
    )
  }



}

export default ProjectileMotionSim;
