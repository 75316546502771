import React, {Component} from 'react';

class Articles extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (

      <div>
        <h1>Articles</h1>
      </div>
    );
  }
}

export default Articles;
